import React, { useState } from 'react';
import MainImage from '../../images/portfolio/Creaza/HeroImage.jpg';
import ConceptArt5 from '../../images/portfolio/Creaza/Concept_5.jpg';
import ConceptArt2 from '../../images/portfolio/Creaza/Concept_2.jpg';
import ConceptArt3 from '../../images/portfolio/Creaza/Concept_3.jpg';
import ConceptArt4 from '../../images/portfolio/Creaza/Concept_4.jpg';
import PortfolioPage from '../../components/PortfolioPage';

const PortfolioCreaza = () => {
    
    const [showVideo, setShowVideo] = useState(false); 

    const onVideoSelect = (videoUrl) => {
      setShowVideo(videoUrl)
    }
    return <PortfolioPage img={MainImage} url="https://player.vimeo.com/video/662589921" title="Creaza" description="Creaza er en nettside for skoler som tilbyr kreative verktøy med faglig innhold og oppgaversamt komplette læremidler i engelsk, samfunnsfag, naturfag og KRLE.The Branch har produsert over 70 videoer for Creaza. Disse videoene går på visualisering av kreative tekster og sanger med faglig innhold. Vi produserte alle visuelle elementene fra design til eventuell animasjon (I noen tilfeller produserte vi også lyden).Vi produserte også vektor tegninger for nettside verktøyet deres cartoonist. Verktøyet gjør det mulig for brukeren å lage egne tegneserier og fortellinger ved hjelp av diverse karakterer, bakgrunnselementer og diverse andre rekvisita. (2021)">
         <div className="flex  mt-32 lg:px-0">
            <div className="max-w-sm">
              <img src={ConceptArt5}  />
              <img src={ConceptArt3} />
            </div>
            <div  className="max-w-sm">
              <img src={ConceptArt2} />
              <img src={ConceptArt4} className="-mt-2" />
            </div>
          </div>
    </PortfolioPage>

}

export default PortfolioCreaza;